import moment from "moment";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_SECRET_KEY;
const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;
class AuthService {
  isAuthenticated() {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return false;
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  logout() {
    localStorage.clear();
  }

  getToken() {
    return localStorage.getItem("encryptToken") || null;
  }

  async getCurrentUserID() {
    const token = await decryptToken();
    if (token != null && token != "" && token != undefined) {
      if (token) {
        return token.Id;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
}

async function decryptToken() {
  try {
    const encryptedData = localStorage.getItem("encryptToken");
    console.log("encryptedData ---->", encryptedData);
    if (!encryptedData) return null;
    const formatBase64 = (b64String) => {
      let output = b64String.replace(/-/g, "+").replace(/_/g, "/");
      while (output.length % 4 !== 0) {
        output += "=";
      }
      return output;
    };

    const safeBase64 = formatBase64(encryptedData);
    const decodedData = window.atob(safeBase64);
    const encryptedBuffer = new Uint8Array(
      Array.from(decodedData).map((char) => char.charCodeAt(0))
    );
    const iv = encryptedBuffer.slice(0, 16);
    const encryptedToken = encryptedBuffer.slice(16);

    const algo = { name: "AES-CBC", iv: iv };
    const encodedKey = new TextEncoder().encode(secretKey.toString());
    const cryptoKey = await window.crypto.subtle.importKey(
      "raw",
      encodedKey,
      algo,
      false,
      ["decrypt"]
    );

    const decrypted = await window.crypto.subtle.decrypt(
      algo,
      cryptoKey,
      encryptedToken
    );
    const decryptedString = new TextDecoder().decode(decrypted);

    const decodeToken = jwtDecode(decryptedString);
    const sub = JSON.parse(decodeToken.sub);
    const { nbf, exp, iat, iss } = decodeToken;
    let tokenObj = {
      ...sub,
      role: sub.Role,
      UserId: sub.Id,
      nbf,
      exp,
      iat,
      iss,
    };

    return tokenObj;
  } catch (error) {
    console.error("Decryption failed:", error);
    throw error;
  }
}

async function decryptObj(obj) {
  const bytes = CryptoJS.AES.decrypt(obj, encryptKey.toString());
  const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
  const decryptedObject = JSON.parse(decryptedString);
  return decryptedObject;
}

export const authService = new AuthService();
