import axiosInstance from "./axiosInstance";
import { axiosInstanceSecured } from "./axiosInstance";
import API_ENDPOINTS from "../config/apiEndpoints";

const GetAll_Service_SubStypes = async () => {
  try {
    let response = await axiosInstanceSecured.post(
      API_ENDPOINTS.Service.GET_ALL_SERVICE.toString(),
      {
        serviceTypeID: 0,
        includeServiceType: false,
        includeSubTypeByServiceTypeID: false,
        includeAll: true,
      }
    );

    // const filteredList = response.result.data.filter(
    //   (item: Entity_SubService) =>
    //     item.serviceSubTypeName.toLowerCase() != "generic"
    // );

    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export { GetAll_Service_SubStypes };
