import React, { useEffect, useState } from "react";
import { Container, Divider, Grid, Typography, Box, Link } from "@mui/material";
import { AccountCircleOutlined } from "@mui/icons-material";
import bannerImage from "../assets/images/logo-white-sm.png";
import homeImage from "../assets/images/App_HomePage.png";
import ProfilePage from "../assets/images/ProfilePage.png";
import ProfileSetting from "../assets/images/ProfileSetting.png";
import DeleteAccountImg from "../assets/images/DeleteAccount.png";

export default function DeleteAccount() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container sx={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Grid container spacing={4}>
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            marginTop: "20px",
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h3"
            color={"primary"}
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Delete your American Golf account
          </Typography>
          <img src={bannerImage} width={"40px"} />
        </Grid>

        <Grid item md={12} sx={{ marginTop: "-20px" }}>
          <Divider />
        </Grid>

        <Grid item md={12}>
          <Typography variant="body1" gutterBottom>
            For security reasons, we can't delete an account for you. You’ll
            need to be able to log into your account to request deletion. If you
            can't remember your password or username, use forgot password option
            to reset your password.
          </Typography>
        </Grid>
        <Grid item md={12}>
          <ol>
            <li>
              <div
                style={{
                  flex: 1,
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" gutterBottom>
                  If you use the American Golf app on Android / iPhone, you’ll
                  need to login into your account in the application
                </Typography>
                <img
                  src={homeImage}
                  width={"200px"}
                  style={{ marginLeft: "30px" }}
                />
              </div>
            </li>
            <li>
              <div
                style={{
                  flex: 1,
                  marginTop: "30px",
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" gutterBottom>
                  Click the &nbsp;{" "}
                  <AccountCircleOutlined sx={{ display: "inline" }} />{" "}
                  &nbsp;profile icon to go to profile page
                </Typography>
                <img
                  src={ProfilePage}
                  width={"200px"}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </li>
            <li>
              {" "}
              <div
                style={{
                  flex: 1,
                  marginTop: "30px",
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" gutterBottom>
                  Tap the Profile setting menu, It will show the delete account
                  option. Tap the delete account option.
                </Typography>
                <img
                  src={ProfileSetting}
                  width={"200px"}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </li>
            <li>
              {" "}
              <div
                style={{
                  flex: 1,
                  marginTop: "30px",
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" gutterBottom>
                  It will ask the account deletion confirmation, Tap yes button
                  to delete your account
                </Typography>
                <img
                  src={DeleteAccountImg}
                  width={"200px"}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </li>
            <li>
              <Typography variant="body1" gutterBottom marginTop={"30px"}>
                Once your account is deleted you will be re-directed to login
                page
              </Typography>
            </li>
          </ol>
        </Grid>
      </Grid>
      <Box
        sx={{
          textAlign: "right",
          paddingY: 2,
          backgroundColor: "white",
        }}
      >
        <Divider />
        <Link
          href="https://www.americangolf.co.uk/content/privacy-policy.html"
          variant="subtitle1"
          color={"#cfcfcf"}
          sx={{ marginRight: "20px" }}
        >
          Privacy Policy
        </Link>
      </Box>
    </Container>
  );
}
