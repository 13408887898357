import axiosInstance from "./axiosInstance";
import { axiosInstanceSecured } from "./axiosInstance";
import API_ENDPOINTS from "../config/apiEndpoints";

export const UpsertCustomer = async (formData) => {
  try {
    const response = await axiosInstanceSecured.post(
      `${API_ENDPOINTS.Customer.UpsertCustomer}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};

export const SearchCustomer = async (email) => {
  try {
    const response = await axiosInstanceSecured.post(
      `${API_ENDPOINTS.Customer.GetCustomer}`,
      {
        pageNumber: 1,
        pageSize: 10,
        customerSearch: {
          name: null,
          email: email,
          cardNumber: null,
          address: null,
          address2: null,
          City: null,
          state: null,
          country: null,
          postalCode: null,
          dob: null,
          contactNumber: null,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};
