import axiosInstance, { axiosInstancePublic } from "./axiosInstance";
import API_ENDPOINTS from "../config/apiEndpoints";

export const Login = async () => {
  try {
    const response = await axiosInstancePublic.post(
      `${API_ENDPOINTS.LOGIN.LOGIN_USER}`,
      {
        email: "AGwebbooking@rheincs.com",
        password: "Qwerty123!",
        rememberMe: true,
        isCustomer: false,
      }
    );

    const encryptedToken = response?.data?.result.accessToken;

    localStorage.setItem("encryptToken", encryptedToken);
  } catch (error) {
    console.log(error);
    if (error.response && error.response?.data) {
      return { isSuccess: false, message: error.response.data };
    } else {
      return {
        isSuccess: false,
        message: "An Internal Error occured. Try again after some time",
      };
    }
  }
};
