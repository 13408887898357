import { ColorModeContext, useMode } from "./theme";
import {
  CssBaseline,
  ThemeProvider,
  Typography,
  CircularProgress,
} from "@mui/material";

import Backdrop from "@mui/material/Backdrop";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Bookings from "./Components/Bookings";
import BookingsV2 from "./Components/BookingsV2";

import { useEffect, useState } from "react";
import { Login } from "./services/Login.Service";
import "react-toastify/dist/ReactToastify.css";
import { authService } from "./services/auth.service";
import { axiosInstancePublic } from "./services/axiosInstance";
import API_ENDPOINTS from "./config/apiEndpoints";
import DeleteAccount from "./Components/DeleteAccount";

const App = () => {
  const [theme, colorMode] = useMode();

  console.log = () => {};
  console.warn = () => {};

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Routes>
          <Route path="/" element={<BookingsV2 />} />
          <Route path="/Delete_your_account" element={<DeleteAccount />} />
        </Routes>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
