import axios from "axios";
import API_ENDPOINTS from "../config/apiEndpoints";
import { authService } from "./auth.service";
import { useLocation } from "react-router-dom";

const axiosInstance = axios.create({
  baseURL: API_ENDPOINTS.BASE_URL,
});
export const axiosInstancePublic = axios.create({
  baseURL: API_ENDPOINTS.BASE_PUBLIC_URL,
});

export const axiosInstanceSecured = axios.create({
  baseURL: API_ENDPOINTS.BASE_PUBLIC_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = authService.getToken();
    config.headers.Authorization = process.env.REACT_APP_AUTH_KEY;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstanceSecured.interceptors.request.use(
  async (config) => {
    const token = authService.getToken();
    config.headers.Authorization = process.env.REACT_APP_AUTH_KEY;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      if (error.response) {
        // Request made and server responded

        // console.log(error.response.data);

        if (error.response.data) {
          return Promise.reject(error.response.data);
        } else {
          return Promise.reject(error.data);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request._response);
        return Promise.reject(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log(error);

        return Promise.reject(error);
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export default axiosInstance;
